import React from 'react';
import PropTypes from 'prop-types';
import Img from 'gatsby-image';
import styled from '@emotion/styled';
import { width, maxWidth, margin } from 'styled-system';

const StyledImg = styled(Img)`
  ${width};
  ${maxWidth};
  ${margin};
`;

const Logo = ({ imageData, alt, ...rest }) => (
  <StyledImg m="auto" fluid={imageData} alt={alt} loading="eager" {...rest} />
);

Logo.propTypes = {
  imageData: PropTypes.shape({
    fluid: PropTypes.object
  }),
  alt: PropTypes.string
};

Logo.defaultProps = {
  imageData: {
    fluid: null
  },
  alt: ''
};

export default Logo;
