import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { YouTube } from '@square-enix-private/fe-media';
import Img from 'gatsby-image';
import PropTypes from 'prop-types';

import {
  Container,
  ImageContainer,
  Image,
  VideoContainer,
  Video,
  NavigationButton,
  NavigationContainer
} from './elements';

const Gallery = ({ media }) => {
  const [index, setIndex] = useState(0);
  const { t } = useTranslation('assets');
  const buttons = t('UI', { returnObjects: true });

  const length = media.length - 1;
  const handleNext = () => (index === length ? setIndex(0) : setIndex(index + 1));
  const handlePrevious = () => (index === 0 ? setIndex(length) : setIndex(index - 1));

  const renderType = media[index].type;

  return (
    <>
      <Container width={1} m="auto">
        {renderType !== 'videos' && (
          <ImageContainer key={`${media[index].id}`}>
            <Image>
              <Img
                fluid={media[index].image}
                src={media[index].image.src}
                alt={media[index].alt}
                title={media[index].title}
              />
            </Image>
          </ImageContainer>
        )}
        {renderType === 'videos' && (
          <VideoContainer height="40vw">
            <Video>
              <YouTube videoId={media[index].videoId} width={1} height="100%" />
            </Video>
          </VideoContainer>
        )}
        <NavigationContainer left={[5, -20, 20]}>
          <NavigationButton
            width={[29, 35]}
            height={[40, 50]}
            onClick={() => handlePrevious()}
            icon={buttons.previousButton.publicURL}
            iconRev={buttons.reversedPreviousButton.publicURL}
            bgHover
          />
        </NavigationContainer>
        <NavigationContainer right={[5, -20, 20]}>
          <NavigationButton
            width={[29, 35]}
            height={[40, 50]}
            onClick={() => handleNext()}
            icon={buttons.nextButton.publicURL}
            iconRev={buttons.reversedNextButton.publicURL}
            bgHover
          />
        </NavigationContainer>
      </Container>
    </>
  );
};

Gallery.propTypes = {
  media: PropTypes.array.isRequired
};

export default Gallery;
