import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Flex } from '@square-enix-private/vaquita-ui';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTwitch } from '@fortawesome/free-brands-svg-icons';
import {
  CountdownContainer,
  TimeContainer,
  Time,
  Count,
  Label,
  CaptionContainer,
  Caption,
  Link,
  Animation
} from './elements';

import {
  calculateTimeDifference,
  leadZeros,
  isInRange,
  delayedDate,
  delay
} from '../../utils';

export const Countdown = ({ date, startDate }) => {
  const { t } = useTranslation('countdown');

  const [time, setTime] = useState({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0
  });

  const [showPulse, setShowPulse] = useState(false);

  const [showContent, setShowContent] = useState(
    isInRange(startDate, delayedDate(date))
  );

  const hideDelayedContent = (time) => {
    setShowPulse(true);
    setTimeout(() => {
      setShowContent(false);
      setShowPulse(false);
    }, time);
  };

  useEffect(() => {
    setShowContent(isInRange(startDate, delayedDate(date)));
    const { days, hours, minutes, seconds } = calculateTimeDifference(date);

    setTime({ days, hours, minutes, seconds });

    let interval;

    if (days + hours + minutes + seconds > 0) {
      interval = setInterval(() => {
        const newTime = calculateTimeDifference(date);

        setTime(newTime);

        const { days, hours, minutes, seconds } = newTime;

        if (days + hours + minutes + seconds === 0) {
          hideDelayedContent(delay);
          clearInterval(interval);
        } else if (!showContent) {
          setShowContent(isInRange(startDate, delayedDate(date)));
        }
      }, 1000);
    } else if (showContent && !showPulse) {
      hideDelayedContent(delayedDate(date).getTime() - Date.now());
    }

    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <Flex width={1}>
      {showContent && (
        <CountdownContainer
          fontSize={['medium']}
          minHeight={[134, 84, 64]}
          p={[null, 'small']}
          flexDirection={['column-reverse', 'row']}
          justifyContent={['space-around', 'center']}
        >
          <CaptionContainer maxWidth={[null, 320]} mx={['small', 'large']}>
            <Link href={t('link')} target="_blank" rel="noopener noreferrer">
              <Flex fontSize={[32, 24, 32]} color="#a970ff" mx={[10, null]}>
                <FontAwesomeIcon size="2x" icon={faTwitch} />
              </Flex>
              <Caption width={200} fontSize={['18px']}>
                {t('title')}
              </Caption>
            </Link>
          </CaptionContainer>

          <TimeContainer
            justifyContent="center"
            fontSize={['largeCounter']}
            css={showPulse ? Animation : null}
          >
            <Time>
              <Count>{leadZeros(time.days)}</Count>
              <Label fontSize={['small', 'medium']}>{t('daysLabel')}</Label>
            </Time>

            <Time>
              <Count>{leadZeros(time.hours)}</Count>
              <Label fontSize={['small', 'medium']}>{t('hoursLabel')}</Label>
            </Time>

            <Time>
              <Count>{leadZeros(time.minutes)}</Count>
              <Label fontSize={['small', 'medium']}>{t('minutesLabel')}</Label>
            </Time>

            <Time>
              <Count>{leadZeros(time.seconds)}</Count>
              <Label fontSize={['small', 'medium']}>{t('secondsLabel')}</Label>
            </Time>
          </TimeContainer>
        </CountdownContainer>
      )}
    </Flex>
  );
};

Countdown.propTypes = {
  date: PropTypes.instanceOf(Date).isRequired,
  startDate: PropTypes.instanceOf(Date).isRequired
};

export default Countdown;
