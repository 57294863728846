import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Box, Flex } from '@square-enix-private/vaquita-ui';
import { useTranslation } from 'react-i18next';

import Logo from '~/components/Logo';
import SectionTitle from '~/components/SectionTitle/index';
import { TabletAndDesktop, PhoneOnly } from '~/components/Buckets';
import { SEHeaderHeight, MobileMenuHeight } from '~/constants/site';
import Count from '~/components/Countdown';
import { PageWrap, Background, Wrapper } from './elements';
import IconScroll from '~/components/IconScroll';

import { TRAILER_WRAPPER } from '~/constants/dataAutomationTagsQA';

import { isInRange, delayedDate } from '../../utils';

const IntroSection = ({
  id,
  LogoSharp,
  poster,
  smallPoster,
  title,
  children
}) => {
  const countdownDate = useTranslation('countdown').t('date');
  const countdownDateStart = useTranslation('countdown').t('dateStart');
  const targetDate = new Date(countdownDate);
  const startDate = new Date(countdownDateStart);

  const [showContent, setShowContent] = useState(
    isInRange(startDate, delayedDate(targetDate))
  );
  const [scrollAlpha, setScrollAlpha] = useState(1);
  let interval;

  const scollListener = () => setScrollAlpha(1 - Math.min(1, (window.scrollY / window.innerHeight) * 5));

  useEffect(() => {
    setShowContent(isInRange(startDate, delayedDate(targetDate)));
    interval = setInterval(() => {
      const inRange = isInRange(startDate, delayedDate(targetDate));

      setShowContent(inRange);
      if (!isInRange) {
        clearInterval(interval);
      }
    }, 1000);
    window.addEventListener('scroll', scollListener);

    return () => {
      clearInterval(interval);
      window.removeEventListener('scroll', scollListener);
    };
  }, []);

  const pageMarginBottom = showContent ? [] : ['medium', 'large'];

  return (
    <Box width={1}>
      <PageWrap
        id={id}
        width={1}
        minHeight={[520, 480]}
        height={[
          `calc(100vh - ${SEHeaderHeight}px)`,
          `calc(100vh - ${SEHeaderHeight}px - 60px)`
        ]}
        mb={pageMarginBottom}
        pt={[0, 60]}
      >
        <PhoneOnly width={1}>
          <Background
            src={smallPoster}
            backgroundPosition={[`center ${MobileMenuHeight}px`, 'center']}
            height="100%"
            width="100%"
          />
        </PhoneOnly>
        <TabletAndDesktop width={1}>
          <Background
            src={poster}
            backgroundPosition={['40% 30%', 'center 15%']}
            backgroundSize="cover"
            height="100%"
            width="100%"
          />
        </TabletAndDesktop>
        <Flex
          data-automation={TRAILER_WRAPPER}
          width="100%"
          height="100%"
          flexDirection="column"
          justifyContent="flex-end"
          alignItems="center"
          px="medium"
        >
          <Box width={['90vw', 420, 600, '40vw']} maxWidth={600}>
            <Logo imageData={LogoSharp} />
          </Box>
          <Box mb={[null, null, null, 'xxlarge']}>
            <SectionTitle
              title={title}
              justifyContent="center"
              textColor="text.secondary"
            />
          </Box>
          <Wrapper height={['50%', '5%']} mt={[null, 'xxlarge']}>
            {children}
          </Wrapper>
          <Flex width={1} flexDirection="column-reverse">
            <Count date={targetDate} startDate={startDate} />
            <IconScroll opacity={scrollAlpha} />
          </Flex>
        </Flex>
      </PageWrap>
    </Box>
  );
};

IntroSection.propTypes = {
  id: PropTypes.string.isRequired,
  LogoSharp: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  poster: PropTypes.string.isRequired,
  smallPoster: PropTypes.string.isRequired,
  children: PropTypes.node
};

IntroSection.defaultProps = {
  children: null
};

export default IntroSection;
