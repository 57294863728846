import styled from '@emotion/styled';
import { Box } from '@square-enix-private/vaquita-ui';
import { left, right } from 'styled-system';
import { Button } from '~/components/Buttons';

export const Container = styled(Box)`
  position: relative;
`;

export const ImageContainer = styled(Box)`
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  overflow: hidden;
`;

export const VideoContainer = styled(Box)`
  display: flex;
  width: 100%;
  align-items: center;
  overflow: hidden;
`;

export const Video = styled(Box)`
  width: 100%;
  height: 100%;
`;
export const Image = styled(Box)`
  width: 100%;
  height: 100%;
`;

export const NavigationContainer = styled(Box)`
  position: absolute;
  transform: translateY(-50%);
  top: 50%;
  ${left};
  ${right};
`;

export const NavigationButton = styled(Button)`
  background-image: url(${({ icon }) => icon});
  background-repeat: no-repeat;

  :hover {
    @supports (-ms-scroll-limit: 0) {
      background-image: url(${({ iconRev }) => iconRev});
    }
  }

  ::after {
    background: unset;
    @supports (clip-path: circle(100% at 50% 50%)) {
      background-image: url(${({ iconRev }) => iconRev});
    }
  }
`;
