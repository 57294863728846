import React from 'react';
import PropTypes from 'prop-types';
import { Flex } from '@square-enix-private/vaquita-ui';
import { SkillImage } from '../elements';

const Skills = ({ skills, ...props }) => (
  <Flex alignItems="flex-start" {...props}>
    {skills.map(({ image: { src } }) => (
      <SkillImage key={src} src={src} />
    ))}
  </Flex>
);

Skills.propTypes = {
  skills: PropTypes.array.isRequired
};

export default Skills;
