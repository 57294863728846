import { Heading, Flex } from '@square-enix-private/vaquita-ui';
import styled from '@emotion/styled';
import { position, style } from 'styled-system';

import BgTexture from '~/assets/buy-bg.jpg';

export const flexGrow = style({
  prop: 'flexGrow',
  cssProperty: 'flexGrow'
});

export const Wrapper = styled(Flex)`
  background-image: url(${BgTexture});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
`;

export const Description = styled(Heading)`
  ${position};
  text-align: center;
`;
