import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Box } from '@square-enix-private/vaquita-ui';
import { Wrapper } from './elements';
import SectionTitle from '../SectionTitle';

const PreOrder = ({ children }) => {
  const { t } = useTranslation('preOrder');

  return (
    <Wrapper
      width={1}
      flexDirection="column"
      justifyContent="space-evenly"
      alignItems="center"
      minHeight={['364px', null, null, '480px']}
    >
      <Box>
        <SectionTitle title={t('title')} upperCase mt={10} />
      </Box>
      {children}
    </Wrapper>
  );
};

PreOrder.propTypes = {
  children: PropTypes.node
};

PreOrder.defaultProps = {
  children: null
};

export default PreOrder;
