import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import { position, zIndex, overflow, space } from 'styled-system';
import Img from 'gatsby-image';
import { Flex, Box as BoxBase } from '@square-enix-private/vaquita-ui';
import GatsbyLink from 'gatsby-link';

export const Link = styled(GatsbyLink)`
  display: block;
  height: 100%;
  text-decoration: none;
  color: inherit;
`;

const Container = styled(BoxBase)`
  border: 2px solid #7802e5;
  border-radius: 2px;
  background-color: #141534;

  & #bgBox {
    transition: all 0.3s ease-in-out;
    transform: scale(1);
  }

  &:hover #bgBox {
    transform: scale(1.1);
  }
`;

const Box = styled(BoxBase)`
  ${position};
  ${zIndex};
  ${overflow};
  ${space};
`;

const Divider = styled.span`
  background-color: #7802e5;
  width: 100%;
  height: 2px;
  display: block;
  margin-top: 10px;
`;

const Gradient = styled.span`
  background: linear-gradient(
    0deg,
    #141534 0%,
    #141534 15%,
    rgba(20, 21, 52, 0) 100%
  );
  width: 100%;
  height: 200px;
  display: block;
  position: absolute;
  top: -150px;
  left: 0;
  z-index: 0;
`;

const Includes = styled.text`
  text-transform: uppercase;
  font-style: italic;
  font-weight: bold;
  font-size: 22px;
`;

const Bullets = styled.text`
  text-transform: uppercase;
  font-weight: lighter;
`;

const BuyBanner = ({ locale }) => {
  const { t } = useTranslation('generics');
  const editions = useTranslation('buyEditons').t('content', {
    returnObjects: true
  });

  return (
    <Flex
      flexDirection={['column', null, 'row']}
      justifyContent="space-around"
      alignItems="center"
    >
      {editions.map(({ logo, background, description, id, slug }) => (
        <Container width={1} maxWidth={380} mx={[0, null, 10]} my={10} key={id}>
          <Link to={`/${locale}/buy/?game=${slug}`}>
            <Box
              position="relative"
              height={['320px', '470px']}
              style={{ overflow: 'hidden' }}
            >
              <Box id="bgBox">
                <Img fluid={background.image} alt={background.alt} />
              </Box>
              <Box width="100%" top="50%" position="absolute">
                <Box px={20} m="auto">
                  <Img fluid={logo.image} alt={logo.alt} />
                </Box>
              </Box>
            </Box>
            <Box minHeight={150} px="small" mt={-30} position="relative">
              <Gradient />
              <Box position="relative" fontSize="large">
                <Includes>{t('includesLabel')}</Includes>
                <Divider />
                <Bullets
                  upperCase
                  dangerouslySetInnerHTML={{ __html: description }}
                />
              </Box>
            </Box>
          </Link>
        </Container>
      ))}
    </Flex>
  );
};

BuyBanner.propTypes = {
  locale: PropTypes.string.isRequired
};

export default BuyBanner;
