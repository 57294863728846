import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import GTMData from '@square-enix-private/fe-gtm/dist/GTMData';
import { Flex, Box } from '@square-enix-private/vaquita-ui';

import SEO from '~/components/SEO/withData';
import IntroSection from '~/components/IntroSection';
import GalleryViewer from '~/components/Gallery';
import SectionTitle from '~/components/SectionTitle/index';
import SectionContainer from '~/components/SectionContainer';
import HighligthsViewer from '~/components/VideosListViewer/highlightsViewer';
import DemoClasses from '~/components/DemoClasses';
import PreOrder from '~/components/PreOrder';
import Lightbox from '~/components/Lightbox';
import { YouTube } from '@square-enix-private/fe-media';
import { Button, InternalLink } from '~/components/Buttons';
import BuyBanner from '~/components/BuyBanner';

import { HIDDEN, VISIBLE } from '~/constants/videoPlayer';
import { TRAILER_BUTTON_PLAY as TRAILER_BUTTON_PLAY_QA } from '~/constants/dataAutomationTagsQA';
import { TRAILER_BUTTON_PLAY as TRAILER_BUTTON_PLAY_ANALYTICS } from '~/constants/dataAutomationTagsAnalytics';

import { useGalleryData, useHighlightsData } from '~/hooks/translations';

export const HomePage = ({
  pageContext: { locale },
  data: { classesList, classesSection }
}) => {
  const { t } = useTranslation('homepage');
  const { image: LogoSharp } = t('logo', { returnObjects: true });
  const poster = t('backgroundImage', { returnObjects: true });
  const smallPoster = t('mobileBackground', { returnObjects: true });
  const comingSoon = t('comingSoon');
  const dispatch = useDispatch();
  const videoPlayer = useSelector((state) => state.videoPlayer);
  const { videoId } = t('trailer', { returnObjects: true });

  return (
    <Flex width={1} flexDirection="column" alignItems="center">
      <GTMData pageId="home" breadcrumb="home" />
      <SEO />
      <IntroSection
        id="home"
        locale={locale}
        poster={poster.image.src}
        smallPoster={smallPoster.image.src}
        LogoSharp={LogoSharp}
        comingSoon={comingSoon}
        title={t('comingSoon')}
      >
        <Flex
          flexDirection={['column', 'row']}
          justifyContent="center"
          alignItems="center"
          width={1}
          maxWidth={[275, 550]}
          mx="medium"
          mb={[null, 'xlarge', null, 'xxlarge']}
        >
          <InternalLink
            variant="secondary"
            to={`/${locale}/buy`}
            data-automation="PosterShotPreOrderLink"
            data-analytics-id="PosterShotPreOrderLink"
          >
            {t('preOrderLabel')}
          </InternalLink>
          <Button
            variant="secondary"
            onClick={() => dispatch({ type: VISIBLE, payload: videoId })}
            data-automation={TRAILER_BUTTON_PLAY_QA}
            data-analytics-id={TRAILER_BUTTON_PLAY_ANALYTICS}
            mt={['medium', 0]}
          >
            {t('trailerLabel')}
          </Button>
        </Flex>
      </IntroSection>
      <SectionContainer id="highlights">
        <SectionTitle title={t('highlightsSectionLabel')} />
        <HighligthsViewer items={useHighlightsData().items} />
      </SectionContainer>
      <DemoClasses classesList={classesList} {...classesSection} />
      <SectionContainer id="gallery">
        <SectionTitle title={t('gallerySectionLabel')} />
        <GalleryViewer media={useGalleryData().items} />
      </SectionContainer>
      <SectionContainer
        id="preOrder"
        px={0}
        pt={0}
        mt={['medium', 'xlarge']}
        maxWidth="unset"
      >
        <PreOrder>
          <Box width="100%" mb={['medium', null, 'xlarge']} maxWidth={[1400]}>
            <BuyBanner locale={locale} />
          </Box>
        </PreOrder>
      </SectionContainer>
      {videoPlayer.isVisible && (
        <Lightbox onCloseModal={() => dispatch({ type: HIDDEN })}>
          <YouTube videoId={videoPlayer.videoID} width={1} height="100%" />
        </Lightbox>
      )}
    </Flex>
  );
};

HomePage.propTypes = {
  pageContext: PropTypes.object,
  data: PropTypes.object
};

HomePage.defaultProps = {
  pageContext: {},
  data: {}
};

export default HomePage;

export const query = graphql`
  query($locale: String!) {
    ...HomepageData
    ...GalleryData
    ...HighlightsData
    ...PreOrderData
    ...AssetsData
    ...FooterData
    ...GenericData
    ...MetadataData
    ...FlagsData
    ...AgeGateData
    ...MenuData
    ...CountDownData

    buyplatforms: demoherointro(filter: { localeDirectory: { eq: $locale } }) {
      platforms: demoPlatforms {
        id
        fill
        displayWidth
        displayHeight
        orientation
        title
        image {
          src
        }
      }
    }

    buyEditons: buyeditions(filter: { localeDirectory: { in: [$locale] } }) {
      id
      label
      slug
      description
      logo {
        image {
          ...FastlyProps
        }
        alt
      }
      background {
        image(crop: "4:5") {
          ...FastlyProps
        }
        alt
      }
    }

    classesSection: democlassessection(
      filter: { localeDirectory: { eq: $locale }, dlc: { eq: "worldslayer" } }
    ) {
      description
      slug
      title
      skillsDescription
      watchVideo
      dlc
    }

    classesList: classeslist(
      filter: { localeDirectory: { eq: $locale }, dlc: { eq: "worldslayer" } }
    ) {
      slug
      order
      link
      description
      title
      borderColor
      dlc
      image {
        alt
        title
        image(maxHeight: 1080) {
          ...FastlyProps
        }
      }
      thumbLink {
        alt
        title
        image {
          ...FastlyProps
        }
      }
      skills {
        title
        alt
        image {
          ...FastlyProps
        }
      }
      icon {
        alt
        title
        image(maxHeight: 120) {
          ...FastlyProps
        }
      }
    }
  }
`;
