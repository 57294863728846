import React from 'react';
import styled from '@emotion/styled';
import { Flex } from '@square-enix-private/vaquita-ui';
import { space, color, layout } from 'styled-system';

const SectionContainer = styled(Flex)`
  position: relative;
  z-index: 0;
  ${space};
  overflow: hidden;
  ${color};
  ${layout};
`;

export default ({ ...rest }) => (
  <SectionContainer
    px={['medium', 'large']}
    pt={['medium', null, 'large']}
    maxWidth={[1400]}
    flexDirection="column"
    width={1}
    {...rest}
  />
);
