import React from 'react';
import styled from '@emotion/styled';
import { Flex, Box } from '@square-enix-private/vaquita-ui';
import {
  fontSize,
  display,
  height,
  width,
  fontFamily,
  minWidth,
  color
} from 'styled-system';
import { css, keyframes } from '@emotion/core';

export const CountdownContainer = styled(Flex)`
  background-color: #0c0c0c;
  ${display};
  width: 100%;
  ${height};
  ${fontFamily};
  ${fontSize};
  box-sizing: content-box;

  @media screen and (min-width: 580px) and (max-width: 650px) {
    flex-direction: column-reverse;
    height: 100px;
  }
`;

export const TimeContainer = styled(Flex)`
  text-align: center;
`;

const bounce = keyframes`
from 0% {
  opacity: 1;
}

50% {
  opacity: 0.5;
}

100% {
  opacity: 1;
}
`;

export const Animation = css`
  animation: ${bounce} 1s ease infinite;
`;

export const CountElement = styled(Flex)`
  ${fontSize};
  text-align: center;
  flex-direction: column;
  justify-content: center;
  ${width};
  ${minWidth};
  ${color};
`;

export const Count = ({ ...rest }) => (
  <CountElement
    fontSize={['10vw', '1em']}
    minWidth={['15vw', 62, 66]}
    color="text.tertiary"
    {...rest}
  />
);

export const Label = styled(Box)`
  writing-mode: vertical-rl;
  text-orientation: upright;
  ${fontSize};
  margin-left: 5px;
  margin-right: 10px;
`;

export const Time = styled(Flex)`
  text-align: center;
`;

export const CaptionContainer = styled(Flex)`
  text-align: center;
  ${fontSize};
  ${width};
  flex-direction: row;
  justify-content: center;
  align-items: center;
  @media screen and (min-width: 580px) and (max-width: 650px) {
    max-width: unset;
  }
`;

export const Caption = styled(Flex)`
  vertical-align: middle;
  word-wrap: break-word;
  align-items: center;
`;

export const Link = styled.a`
  text-decoration: none;
  color: inherit;
  display: flex;

  &:first-of-type > div:first-of-type {
    transition: transform 0.2s;
  }
  &:first-of-type:hover > div:first-of-type {
    transform: scale(1.2);
  }
`;
