import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Flex } from '@square-enix-private/vaquita-ui';
import SectionTitle from '~/components/SectionTitle';
import GatsbyImage from 'gatsby-image';
import {
  CharacterBox,
  CharacterBoxBorder,
  FadeOutLink,
  DescriptionArea,
  Icon,
  CharacterTitle,
  CharacterDescription,
  SkillsDescription,
  SkillsArea,
  MobileSkillsArea,
  MobileCharacterImage,
  MobileItemsWrapper,
  DescriptionText,
  PreviousArrow,
  NextArrow,
  PageIndicatorWrapper,
  MobileCharacterWrapper,
  AnimatedFlex,
  MobileDescriptionWrapper
} from './elements';
import { DesktopOnly, MobileOnly } from '../Buckets';
import { FlexWrapper } from '../siteMenu/elements';
import { ExternalLink } from '../Buttons';
import Skills from './components/Skills';

const DemoClasses = ({
  classesList,
  description,
  title,
  skillsDescription,
  watchVideo
}) => {
  const [selected, setSelected] = useState(0);

  return (
    <>
      <Box width={1} px={[20, 40]} pt={20}>
        <SectionTitle
          mx={['auto', 0]}
          mt={['large', 'xlarge']}
          mb={0}
          title={title}
          justifyContent={['center', 'left']}
        />
        <DescriptionText
          mb="20px"
          maxWidth="800px"
          textAlign={['center', null, 'left']}
          fontSize="24px"
        >
          {description}
        </DescriptionText>
        <DesktopOnly>
          <Flex width="100%">
            {classesList.map((o, i) => (
              <CharacterBoxBorder
                key={o.slug}
                width={i === selected ? '49%' : '15%'}
                mx="1%"
                onMouseEnter={() => setSelected(i)}
                onClick={() => setSelected(i)}
                borderColor={o.borderColor}
                className={i === selected ? 'selected' : ''}
              >
                <CharacterBox
                  className={i === selected ? 'selected' : ''}
                  onClick={() => setSelected(i)}
                  src={o.image.image.src}
                >
                  <FadeOutLink
                    className={i === selected ? '' : 'hidden'}
                    href={o.link}
                    target="_blank"
                    rel="noopener nofollow"
                  >
                    <GatsbyImage
                      width="164px"
                      height="102px"
                      fluid={o.thumbLink.image}
                      alt={o.thumbLink.alt}
                    />
                  </FadeOutLink>

                  <DescriptionArea className={i === selected ? 'left' : ''}>
                    <Icon src={o.icon.image.src} m="auto" mb="-10px" />
                    <CharacterTitle>{o.title}</CharacterTitle>
                    <CharacterDescription>{o.description}</CharacterDescription>
                  </DescriptionArea>
                  <SkillsArea className={i === selected ? '' : 'hidden'}>
                    <SkillsDescription>{skillsDescription}</SkillsDescription>
                    <Skills skills={o.skills} />
                  </SkillsArea>
                </CharacterBox>
              </CharacterBoxBorder>
            ))}
          </Flex>
        </DesktopOnly>
      </Box>
      <MobileOnly width="100%" flexDirection="column">
        <PageIndicatorWrapper>
          {`${selected + 1} / ${classesList.length}`}
        </PageIndicatorWrapper>
        <MobileItemsWrapper>
          <AnimatedFlex ml={`${selected * -100}vw`}>
            {classesList.map((o, i) => (
              <MobileCharacterWrapper key={o.slug}>
                <MobileCharacterImage
                  src={o.image.image.src}
                  minHeight={['510px', '640px']}
                >
                  {i !== 0 && (
                    <PreviousArrow onClick={() => setSelected((i) => i - 1)} />
                  )}
                  {i !== classesList.length - 1 && (
                    <NextArrow onClick={() => setSelected((i) => i + 1)} />
                  )}
                </MobileCharacterImage>
                <FlexWrapper
                  alignItems="center"
                  justifyContent="center"
                  pr="22px"
                >
                  <Icon src={o.icon.image.src} />
                  <MobileDescriptionWrapper>
                    <CharacterTitle textAlign="left">{o.title}</CharacterTitle>
                    <CharacterDescription textAlign="left">
                      {o.description}
                    </CharacterDescription>
                  </MobileDescriptionWrapper>
                </FlexWrapper>

                <MobileSkillsArea>
                  <SkillsDescription textAlign="center">
                    {skillsDescription}
                  </SkillsDescription>
                  <Skills
                    skills={o.skills}
                    justifyContent="center"
                    flexWrap="wrap"
                  />
                </MobileSkillsArea>
                <Flex justifyContent="center" mt="medium">
                  <ExternalLink href={o.link} variant="secondary">
                    {watchVideo}
                  </ExternalLink>
                </Flex>
              </MobileCharacterWrapper>
            ))}
          </AnimatedFlex>
        </MobileItemsWrapper>
      </MobileOnly>
    </>
  );
};

DemoClasses.propTypes = {
  classesList: PropTypes.array,
  title: PropTypes.string,
  description: PropTypes.string,
  skillsDescription: PropTypes.string,
  watchVideo: PropTypes.string
};

DemoClasses.defaultProps = {
  classesList: [],
  title: '',
  description: '',
  skillsDescription: '',
  watchVideo: ''
};

export default DemoClasses;
