import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { VISIBLE } from '~/constants/videoPlayer';
import { Item, ItemWrapper, HighlightsGrid } from './elements';

const linkRegex = new RegExp('link{(.*)}');

const HighligthsViewer = ({ items }) => {
  const dispatch = useDispatch();

  return (
    <HighlightsGrid
      flexDirection={['column', 'unset']}
      justifyContent={['center', 'space-between']}
      gridTemplateColumns={[null, 'repeat(2, 1fr)', 'repeat(3, 1fr)']}
    >
      {items.map(({ image, title, id, videoId, alt }) => {
        const isExternal = linkRegex.test(videoId);
        const onClick = () => {
          if (isExternal) {
            const link = videoId.replace('link{', '').replace('}', '');

            window.open(link, '_blank');
          } else {
            dispatch({ type: VISIBLE, payload: videoId });
          }
        };

        return (
          <ItemWrapper mb="medium" key={id}>
            <Item
              imgData={image}
              alt={alt}
              description={title}
              onClick={onClick}
              playVisible
            />
          </ItemWrapper>
        );
      })}
    </HighlightsGrid>
  );
};

HighligthsViewer.propTypes = {
  items: PropTypes.array.isRequired
};

export default HighligthsViewer;
