import React from 'react';
import PropTypes from 'prop-types';
import { Flex } from '@square-enix-private/vaquita-ui';
import styled from '@emotion/styled';
import { position, top, bottom, right, opacity } from 'styled-system';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown } from '@fortawesome/free-solid-svg-icons';
import { keyframes } from '@emotion/core';
import { transform } from '~/custom/styled-system';
import iconBkg from '../SocialLinks/icon-background.png';

const bounce = keyframes`
  0% {
    transform:translateY(-2px);
  }

  100% {
    transform:translateY(2px);
  }
`;

export const IconBox = styled(Flex)`
  align-items: center;
  justify-content: center;
  font-size: 1.8em;
  background-image: url('${iconBkg}');
`;

export const StyledFontAwesome = styled(FontAwesomeIcon)`
  width: 100%;
  height: 100%;
  animation: ${bounce} 1s 0.5s infinite;
`;

export const PositionContainer = styled(Flex)`
  ${position};
  ${top};
  ${bottom};
  ${right};
  ${transform};
  ${opacity};
  z-index: 1;
`;

const IconScroll = ({ opacity }) => (
  <PositionContainer
    position="relative"
    width={1}
    height="100%"
    opacity={opacity}
  >
    <PositionContainer position="absolute" right={0} bottom={0}>
      <IconBox fontSize="1.8em" mx={['small']} width={46} height={46}>
        <StyledFontAwesome size="1x" icon={faAngleDown} />
      </IconBox>
    </PositionContainer>
  </PositionContainer>
);

IconScroll.propTypes = {
  opacity: PropTypes.number
};

IconScroll.defaultProps = {
  opacity: 1
};

export default IconScroll;
